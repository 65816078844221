<template>
<div class="list-group">
    <div class="reports-item">
        <table>
            <tr>
                <th>PUAN</th>
                <th>DOĞRU</th>
                <th>BOŞ</th>
                <th>YANLIŞ</th>
            </tr>
            <tr>
                <td>{{detail.header.point}}</td>
                <td>{{detail.header.correct}}</td>
                <td>{{detail.header.empty}}</td>
                <td>{{detail.header.wrong}}</td>
            </tr>
        </table>
        <table>
            <tr>
                <th style="width: 25%;">DERSLER</th>
                <th style="width: 15%;">SORU</th>
                <th style="width: 15%;">DOĞRU</th>
                <th style="width: 15%;">BOŞ</th>
                <th style="width: 15%;">YANLIŞ</th>
                <th style="width: 15%;">NET</th>
            </tr>
            <tr v-for="lesson in detail.parentLesson" :key="lesson.name">
                <td>{{lesson.name}}</td>
                <td>{{lesson.question}}</td>
                <td>{{lesson.correct}}</td>
                <td>{{lesson.empty}}</td>
                <td>{{lesson.wrong}}</td>
                <td>{{lesson.net}}</td>
            </tr>
        </table>
    </div>
    <div class="reports-item">
        <table>
            <tr>
                <th style="width: 25%;">DERSLER</th>
                <th style="width: 15%;">SORU</th>
                <th style="width: 15%;">DOĞRU</th>
                <th style="width: 15%;">BOŞ</th>
                <th style="width: 15%;">YANLIŞ</th>
                <th style="width: 15%;">NET</th>
            </tr>
            <tr v-for="lesson in detail.childLesson" :key="lesson.name">
                <td>{{lesson.name}}</td>
                <td>{{lesson.question}}</td>
                <td>{{lesson.correct}}</td>
                <td>{{lesson.empty}}</td>
                <td>{{lesson.wrong}}</td>
                <td>{{lesson.net}}</td>
            </tr>
        </table>
    </div>
    <div class="reports-item" v-for="lesson in detail.parentLesson" :key="lesson.name">
        <table>
            <tr>
                <th style="width: 60%;">{{lesson.name}} - Yetkinlik</th>
                <th style="width: 20%;">Doğru</th>
                <th style="width: 20%;">Seçilen</th>
            </tr>
            <tr :class="gain.isSelected==null ? 'empty' : gain.isTrue == false? 'wrong': null" v-for="gain in lesson.gains" :key="lesson.name+'-'+gain.viewOrder">
                <td>{{gain.gain}}</td>
                <td>{{gain.isCorrect}}</td>
                <td>{{gain.isSelected}}</td>
            </tr>
        </table>
    </div>
</div>
</template>

<script>
import {
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BTooltip,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BProgress
} from 'bootstrap-vue'
export default {
    components: {
        BCardHeader,
        BCardBody,
        BRow,
        BCol,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BSidebar,
        BTooltip,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BFormCheckbox,
        BInputGroupPrepend,
        BInputGroup,
        BProgress
    },
    data() {
        return {
            detail: {}
        }
    },
    async created() {
        var response = await this.$http.get("Report/Exam/" + this.$route.params.userId + "/" + this.$route.params.examId);
        this.detail = response.data.data;
        console.log(this.detail);
    }
}
</script>

<style scoped>
.list-group {
    width: 100%;
    height: auto;
    display: grid;
    grid-gap: 1em;
    grid-template-columns: 50% 50%;
    background-color: #11151c;
    border-radius: 8px;
    box-shadow: 0px 0.125em 3em rgba(0, 0, 0, 0.05);
    padding: 1em;
}

.list-group .reports-item {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
}

.list-group .reports-item table {
    width: 100%;
    height: auto;
    background-color: #11151c;
    border-radius: 8px;
    border-collapse: collapse;
    overflow: hidden;
    margin-bottom: 2em;
}

.list-group .reports-item table tr:first-child {
    background-color: #17bf63 !important;
    border-bottom: none;
}

.list-group .reports-item table tr {
    width: 100%;
    height: auto;
    background-color: #151922;
    border-bottom: 1px solid #181d28;
}

.list-group .reports-item table tr.wrong {
    background-color: #dc3545;
    border-bottom: 1px solid #dc3545;
}

.list-group .reports-item table tr.empty {
    background-color: #0dcaf0;
    border-bottom: 1px solid #0dcaf0;
}

.list-group .reports-item table tr:last-child {
    border-bottom: none;
}

.list-group .reports-item table tr th {
    font-size: .75em;
    font-weight: 700;
    text-align: left;
    color: #ffffff;
    min-height: 50px;
    padding: 1em 0.5em;
}

.list-group .reports-item table td {
    font-size: .75em;
    font-weight: 500;
    color: #ffffff;
    min-height: 50px;
    padding: 1em 0.75em;
}
</style>
